<template>
  <div class='border-t bg-white flex flex-col py-8'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>대한비만학회</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-48 '>Contact Number</span>02-6941-0888</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-48 '>Fax</span>02-364-0883</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-48 '>E-mail</span>webmaster@kosso.or.kr</div>
        <div class='mt-2'><span class='uppercase inline-block opacity-70 w-48 '>Address</span>[04195] 서울특별시 마포구 만리재로 14 르네상스 타워 1010호</div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>제57차 대한비만학회 춘계학술대회 운영 사무국</div>
        <div class='mt-2'><span class='uppercase inline-block w-48 opacity-70'>Contact Number</span>02-2039-5706</div>
        <div class='mt-2'><span class='uppercase inline-block w-48 opacity-70'>E-mail</span>kssoasc2023@into-on.com</div>
      </div>
      <div class='flex flex-col lg:justify-start lg:items-start justify-center items-center'>
        <img :src='eventConfigLogoImageUrl'
             class='h-10 cursor-pointer mb-6'
             @click='goToHome'/>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'KossoascLayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken'
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventMainThemeColor'
    ]),
    footerNavStyle () {
      return {
        '--color': this.eventMainThemeColor
      }
    }
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
